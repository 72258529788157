<template>
  <div class="container">
    <!-- 保存排序/添加分类 -->
    <div class="header-button">
      <el-button @click="openSortDialog('addParent')" type="primary">添加分类</el-button>
    </div>
    
    <!-- 分类列表 -->
    <el-table class="sort-table"
      row-key="id"
      :data="sortList"
      :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
      :tree-props="{hasChildren: 'hasChildren'}">
      <el-table-column prop="name" label="分类名称"></el-table-column>
      <el-table-column prop="num" label="商品数量"></el-table-column>
      <el-table-column label="顺序" width="160px">
        <template slot-scope="scope">
          <el-button @click="updateSrotWeight('up', scope.row, scope)"
            :disabled="scope.row.id == sortList[0].id" size="small" class="el-icon-top"></el-button>
          <el-button @click="updateSrotWeight('down', scope.row, scope)"
            :disabled="scope.row.id == sortList[sortList.length - 1].id" size="small" class="el-icon-bottom"></el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240px">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.parentId != 0" :style="{'opacity': scope.row.parentId != 0 ? 0 : 1}"
            @click="openSortDialog('addChildren', scope.row)" type="text" size="small">新增子分类</el-button>
          <el-button type="text" size="small"
            @click="openSortDialog(`${scope.row.parentId == 0 ? 'editParent' : 'editChildren'}`, scope.row)">编辑</el-button>
          <el-button @click="$router.push({path: '/shop/goods/list'})" type="text" size="small">查看商品</el-button>
          <el-button @click="deleteSort(scope.row)"
            type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加/修改分类弹窗 -->
    <el-dialog title="新增/修改分类" width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogSort.enabled">
      <el-form label-width="80px">
        <el-form-item label="分类名称">
          <el-input v-model="sort.name" placeholder="建议不超过4个中文或8个英文"></el-input>
        </el-form-item>
        <el-form-item label="上级分类" 
          v-if="dialogSort.type != 'addParent' && dialogSort.type != 'addChildren' && dialogSort.type != 'editParent'">
          <el-select v-model="sort.parentId" placeholder="请选择上级分类">
            <el-option
              v-for="item in sortList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类图片">
          <div class="logo">
            <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadImage">
              <div class="brochure-logo">
                <img v-if="sortImage" :src="sortImage">
                <template v-else><p class="add">+</p><p>立即上传</p></template>
              </div>
            </file-upload>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSort.enabled = false">取 消</el-button>
        <el-button type="primary" @click="updateSort()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FileUpload from "@/websites/cms/components/FileUpload"
export default {
  data(){
    return {
      sortList: [],
      dialogSort: {
        enabled: false,
        type: '',
        item: {}
      },
      sort: {
        name: "",
        images: "",
        parentId: 0
      },
      sortImage: ""
    }
  },
  created(){
    this.getSortList();
  },
  methods: {
    // 查询分类列表
    async getSortList(){
      let cid = this.$localStorage('ZGCMS_ACCOUNT')
      let res = await this.$ZGManager.getSortList(1, cid.companyId);
      if(res.status != 200) return;
      let result = [];
      
      // 先循环一遍，将父级分类推入到分类数组，将子级分类推入到result数组
      res.data.map((e) => {
        if(e.parentId == 0) {
          e.children = [];
          this.sortList.push(e);
        }else{
          result.push(e);
        }
      });

      // 再循环sortList父级分类数组，从result数组中找到对应的子类
      this.sortList.map((e, index) => {
        result.map((item) => {
          if(e.id == item.parentId) this.sortList[index].children.push(item);
        });
      });
    },

    // 新增/更新分类
    async updateSort(){
      if(this.dialogSort.type == 'addParent'){
        let params = {name: this.sort.name, images: this.sortImage, parentId: 0};
        let res = await this.$ZGManager.createSort(params);
        if(res.status !== 200) return;
      }else if(this.dialogSort.type == 'addChildren'){
        let params = {name: this.sort.name, images: this.sortImage, parentId: this.dialogSort.item.id};
        let res = await this.$ZGManager.createSort(params);
        if(res.status !== 200) return;
      }else{
        let params = {name: this.sort.name, images: this.sortImage, parentId: this.sort.parentId};
        let res = await this.$ZGManager.updateSort(this.dialogSort.item.id, params);
        if(res.status !== 200) return;
      }
      this.dialogSort.enabled = false;
      this.$router.go(0);
    },

    // 删除分类
    async deleteSort(item){
      let dialog = await this.$confirm(`此操作将永久删“${item.name}”分类, 是否继续?`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      });
      if(dialog != 'confirm') return;
      let res = await this.$ZGManager.deleteSort(item.id);
      if(res.status !== 200) return;
      this.$message.success("删除成功");
      this.getParentSortList();
    },

    // 调整分类权重
    async updateSrotWeight(type, item, scope){
      let res = await this.$ZGManager.offsetSort(item.id, type == 'up' ? -1 : 1);
      if(res.status !== 200) return;
      this.$router.go(0);
    },

    // 打开编辑分类弹窗
    openSortDialog(type, item){
      this.sort = {};
      this.dialogSort.type = type;
      if(item){
        this.dialogSort.item = item || {};
        if(type == 'editParent' || type == 'editChildren'){
          this.sort = item;
          this.sortImage = item.images;
        }else{
          this.sort.parentId = item.id;
        }
      }
      this.dialogSort.enabled = true;
    },

    // 上传分类图片
    uploadImage(data){
      this.sortImage = data.url;
    },
  },
  components:{
    'file-upload': FileUpload
  }
}
</script>

<style lang="scss" scoped>
.header-button{
  text-align: right;
}
.sort-table{
  width: 100%;
  margin-top: 20px;
}
.logo{
  width: 130px;
  height: 130px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fafafa;
  text-align: center;
  position: relative;
  float: left;
  .add{
    font-size: 50px;
    font-weight: 100;
    margin-top: 30px;
  }
  img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>